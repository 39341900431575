import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator, QueryParams } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { AuthorizationServiceRESTRoleService, ReadRole } from '../authorization/generated';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RoleDataService extends DefaultDataService<ReadRole> {
    constructor(
        http: HttpClient,
        httpUrlGenerator: HttpUrlGenerator,
        private roleApi: AuthorizationServiceRESTRoleService
    ) {
        super('ReadRole', http, httpUrlGenerator);
    }

    getWithQuery(queryParams: QueryParams | string): Observable<ReadRole[]> {
        // Get all roles assigned to an specific user
        if (queryParams['companyCode'] && queryParams['username']) {
            return this.roleApi.getUserRoles({ company: queryParams['companyCode'], username: queryParams['username'] });
        }

        // Get role by id and company code
        if (queryParams['companyCode'] && queryParams['roleId']) {
            return this.roleApi.getRoleById({ company: queryParams['companyCode'], id: parseInt(queryParams['roleId']) }).pipe(map(role => [role]));
        }

        // Get DEFAULT or REGULAR roles by company
        if (queryParams['companyCode'] && queryParams['roleType']) {
            return this.roleApi.getAllRoles({ company: queryParams['companyCode'], type: queryParams['roleType'] });
        }

        // Get ALL roles by company DEFAULT & REGULAR
        if (queryParams['companyCode']) {
            return this.roleApi.getAllRoles({ company: queryParams['companyCode'] });
        }
    }
}
