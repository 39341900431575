import { Injectable } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Route, Router, UrlSegment } from '@angular/router';
import { AppConfigService } from '@app/service/app-config.service';
import { DocumentTitleService } from '@app/service/document-title.service';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

@Injectable()
export class NavigationService {
    public url$: Observable<UrlSegment> = this.router.events.pipe(
        filter(e => e instanceof NavigationEnd),
        map(() => this.route.firstChild),
        tap((activatedRoute: ActivatedRoute) => {
            let route = activatedRoute;
            const routes = [];
            while (route.firstChild) {
                route = route.firstChild;
                routes.push(route);
            }
            let page, mainPathParam;
            if (routes.length > 1) {
                page = routes[1].snapshot.data['title'];
            }

            if (routes.length > 2) {
                mainPathParam = routes[2].snapshot.data['title'];
            }

            if (page && mainPathParam) {
                this.documentTitle.setTitle(mainPathParam);
            } else if (page) {
                this.documentTitle.setTitle(page);
            } else {
                this.documentTitle.setTitle(`Home`);
            }
        }),
        map((activatedRoute: ActivatedRoute) => activatedRoute.firstChild),
        mergeMap(data => data.url),
        map(url => url[0])
    );

    public isShellSubject = new ReplaySubject<boolean>(1);
    public currentAppSubject = new ReplaySubject<Route>(1);

    public isShell = toSignal(this.isShellSubject);
    public currentApp = toSignal(this.currentAppSubject);

    private routes: Route[] = this.router.config[0].children;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private appConfig: AppConfigService,
        private documentTitle: DocumentTitleService
    ) {
        this.url$
            .pipe(
                filter(url => !!url),
                tap(url => {
                    this.isShellSubject.next(!this.isApp(url));
                    if (this.isApp(url)) {
                        const routerConfig = this.routes.find(p => p.path === url.path);
                        this.currentAppSubject.next(routerConfig);
                    }
                })
            )
            .subscribe();
    }

    get isShell$(): Observable<boolean> {
        return this.isShellSubject.asObservable();
    }

    get apps() {
        return this.appConfig.apps;
    }

    public isApp(url: UrlSegment): boolean {
        return this.appConfig.isApp(url.path);
    }

    public getRoutes(app: Route): Route[] {
        const config = (this.routes.find(route => route.path === app.path) as any)._loadedRoutes;
        return config || [];
    }

    public getRouteDisplayName = (route: Route): string => {
        const sentenceCase = route.path[0].toUpperCase() + route.path.slice(1);
        return sentenceCase.split('-').join(' ');
    };
}
